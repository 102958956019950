$( document ).on('turbolinks:load', function() {
    $('.dropdown, .dropdown-menu').hover(function() {
        $(this).addClass('show');
        $(this).children('.dropdown-menu').addClass('show');
    },
    function() {
        $(this).removeClass('show');
        $(this).children('.dropdown-menu').removeClass('show');
    });
});
