// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery")
require("jquery-ui")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("gon")
require("slick-carousel")
require("objectFitPolyfill")
require("./map")
require ("./scroll_navbar");
require('./tooltip');
require('./recaptcha.js');

import 'bootstrap'
import 'chosen-js/chosen.css'
import 'chosen-js'
import 'bootstrap4c-chosen/dist/css/component-chosen.css'
import 'slick-carousel'
import "slick-carousel/slick/slick.scss"; 
import "slick-carousel/slick/slick-theme.scss";
import '../stylesheets/application'

import '@fortawesome/fontawesome-free/js/all'
import "@fortawesome/fontawesome-pro/js/all"
import "@fortawesome/fontawesome-pro/css/all"

require('./custom')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
 const images = require.context('../images', true, /\.(gif|jpg|png|svg)$/i)
 const imagePath = (name) => images(name, true)
