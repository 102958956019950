import Rails from '@rails/ujs';

$(document).ready(function() {

  if ($('.contact_requests').length > 0) {
    initializeRecaptcha();
  }

  // Contact form
  $(`#${ gon.form_id } input[type="submit"]`).click(function(e) {
    e.preventDefault();

    submitRecaptchableForm(gon.form_id,
                           gon.recaptcha_token_field_id,
                           gon.recaptcha_action)

    return false;
  });


});

function initializeRecaptcha() {  
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `https://www.google.com/recaptcha/api.js?render=${ gon.recaptcha_site_key }`;
  document.getElementsByTagName("head")[0].appendChild(script);
  return false;
}

function submitRecaptchableForm(formId, tokenFieldId, action) {
  grecaptcha.ready(function() {
    grecaptcha.execute(`${ gon.recaptcha_site_key }`, { action: action }).then(function(token) {
    
      document.getElementById(tokenFieldId).value = token;
    
      var recaptchaedForm = document.querySelector('#' + formId);

      var isRemote = $(recaptchaedForm).data('remote');
      if (isRemote) {
        Rails.fire(recaptchaedForm, 'submit');
      }
      else {
        recaptchaedForm.submit();
      }
    });
  });
}