$(document).on('turbolinks:load', function() {
    
  $(function() {
    
        var setInvisible = function(elem) {
          elem.css('visibility', 'hidden');
          elem.css('width', '0');
          
        };
        var setVisible = function(elem) {
          elem.css('visibility', 'visible');
          elem.css('width', '35px');
        };
    
        var elem = $("#product-nav-list");
        var items = elem.children();
    
        // Inserting Buttons
        elem.prepend('<div id="right-button" style="visibility: hidden; width: 0;"><i class="icon fal fa-chevron-left"></i></div>');
        elem.append('<div id="left-button" style="visibility: hidden; width: 0;"><i class="icon fal fa-chevron-right"></i></div>');
    
        // Inserting Inner
        items.wrapAll('<div id="inner" />');
        
        // Inserting Outer
        elem.find('#inner').wrap('<div id="outer"/>');
        var outer = $('#outer');


        var updateUI = function() {
          var maxWidth = outer.outerWidth(true);
          var actualWidth = 0;

          $.each($('#inner >'), function(i, item) {
            actualWidth += $(item).outerWidth(true);
          })
          .promise()
          .done(function() {
            if (!(actualWidth <= maxWidth)) {
              setVisible($('#left-button'));
              setVisible($('#right-button'));  
            } else {
              setInvisible($('#left-button'));
              setInvisible($('#right-button'));
            }
          })
        };
        updateUI();
    
        $('#right-button').on('click', function() {
          var leftPos = outer.scrollLeft();
          outer.animate({
            scrollLeft: leftPos - 200
          }, 800, function() {
            if ($('#outer').scrollLeft() <= 0) {
              // setInvisible($('#right-button'));
            }
          });
        });
    
        $('#left-button').on('click', function() {
          setVisible($('#right-button'));
          var leftPos = outer.scrollLeft();
          outer.animate({
            scrollLeft: leftPos + 200
          }, 800);
        });
    
        $(window).on('resize', function() {
          updateUI();
        });
      });
    
});
