$(document).on('turbolinks:load', function() {
    
    if ($('.homepage').length || $('.contact_requests').length) {
        handler = Gmaps.build('Google');
        handler.buildMap({
            provider: {
                backgroundColor: 'white',
                disableDefaultUI: true,
                zoomControl: true,
            },
            internal: {
                id: 'map'
            }
        },
        function() {
            markers = handler.addMarkers([
                {
                    "lat": 41.05946,
                    "lng": -112.00054,
                }
            ]);
            handler.bounds.extendWith(markers);
            handler.fitMapToBounds();
            handler.getMap().setZoom(14);

            // Potentially append info card when map renders
            google.maps.event.addListenerOnce(handler.getMap(), 'idle', function(e) {
                $(".gm-style").append('');
            });
        }
        );  
    }
});